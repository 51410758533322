import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../../utility/utils"


const GiftCardsMobile = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/mobile/ng/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Gift Card</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-black title-bottom--spacing no-margins">
                                GIFT CARD DISCLAIMER
                            </h1>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                            Arrangement with Nomiworld Limited - GIFTCARDS
                        </h1>
                        <p className="color-black text-bold bottom--spacing">
                            DISCLAIMER
                        </p>
                        <p className="color-black bottom--spacing">
                            <span className="text-bold">IMPORTANT:</span> {" "}You must read the following disclaimer carefully before purchasing any of the Giftcards. The Kuda application and its contents are confidential and should not be distributed, published or reproduced (in whole or in part) or disclosed by recipients to any other person.
                        </p>
                        <p className="color-black bottom--spacing">
                            Nothing in this electronic transmission constitutes an offer by Kuda Technologies Limited or Kuda Microfinance Bank Limited (together “Kuda”) to sell the giftcards. The giftcards are being offered and sold by Nomiworld Limited. Nomiworld Limited has confirmed to Kuda that it properly acquired the giftcards and that it has the right to resell the gitftcards using digital platforms. The terms and conditions governing the purchase and use of each giftcard is as set out by the respective retailer, vendor or service provider whose giftcards you have purchased (the “Retailers”). Please visit the website of the Retailer(s) whose giftcard(s) you wish to purchase and review the terms and conditions applicable to the giftcards thoroughly before you proceed to purchase any giftcard.
                        </p>
                        <p className="color-black bottom--spacing">
                            Kuda will not be liable (either civil or criminal) for any loss that you may suffer by reason of your purchase of a giftcard that is being offered by Nomiworld Limited through the Kuda app. All recourse available to you shall be as set out in the terms and conditions as issued by the respective Retailers. In addition, all customer service support in relation to the use of the giftcard shall be provided to you directly by the Retailers.
                        </p>
                        <p className="color-black bottom--spacing">
                            You acknowledge and agree that no person in Kuda has nor is held out as having any authority to give any statement, warranty, representation, or undertaking on behalf of Kuda in connection with the sale of the giftcards.
                        </p>
                        <p className="color-black bottom--spacing">
                            No representation or warranty, express or implied is, or will be, given by Kuda or its employees, officers or directors or any other person as to the validity or fairness of the price of the giftcards. Kuda shall have no responsibility or liability whatsoever for any errors, omissions, misstatements, negligence or otherwise, relating to the giftcards.
                        </p>
                        <p className="color-black bottom--spacing">
                            You confirm that you shall not resell, in whole or in part, any of the giftcards that you purchase through the Kuda app and you shall not engage in any fraudulent activity or breach any Anti-Money Laundering laws applicable in any jurisdiction, in your dealings with the giftcards.
                        </p>
                        <p className="color-black bottom--spacing">
                            By processing payment, you confirm that you have read our disclaimer and the terms and conditions issued by the Retailers thoroughly and have accepted the contents contained therein.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GiftCardsMobile
